import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IHTTPRequestOptions, sendCommerceRequest } from '@msdyn365-commerce/core';
export interface IMFIConfirmationPageParameters {
    transactionID: string | undefined;
    isOrderConfirmationDisplayed: boolean;
}

interface IMFIConfirmationPageStatusResponse {
    "@odata.context": string;
    OrderConfirmationResponse: {
        Status: boolean;
        Message: string;
    };
}

export class UpdateConfirmationPageStatusInput implements IActionInput {
    public input: IMFIConfirmationPageParameters;

    constructor(input: IMFIConfirmationPageParameters) {
        this.input = input;
    }
    public getCacheKey = () => 'UpdateConfirmationPageStatusResult';
    public getCacheObjectType = () => 'UpdateConfirmationPageStatusResult';
    public dataCacheType = (): CacheType => 'none';
}

// Uncomment and implement createInput function if necessary

async function updateConfirmationPageStatusAction(
    request: UpdateConfirmationPageStatusInput,
    ctx: IActionContext
): Promise<IMFIConfirmationPageStatusResponse | null> {
    const { apiSettings } = ctx.requestContext;
    const requestUrl = `${apiSettings.baseUrl}Commerce/OrderConfirmation/MFIUpdateConfirmationPageStatus?api-version=7.3`;
    const requestOptions: IHTTPRequestOptions = {
        headers: {
            oun: apiSettings.oun,
            'Content-Type': 'application/json'
        }
    };
    const requestBody = {
        orderConfirmationParam: {
            transactionID: request.input.transactionID,
            isOrderConfirmationDisplayed: request.input.isOrderConfirmationDisplayed
        }
    };
    const response = await sendCommerceRequest<IMFIConfirmationPageStatusResponse>(requestUrl, 'post', requestBody, requestOptions);
    if (response.status >= 200 && response.status < 300 && response.data) {
        return response.data;
    } else if (response.data) {
        throw response.data;
    }
    throw new Error('[updateConfirmationPageStatusAction] Invalid response received from RetailServer');
}
export default createObservableDataAction({
    action: <IAction<IMFIConfirmationPageStatusResponse | null>>updateConfirmationPageStatusAction,
    id: 'UpdateConfirmationPageStatusResult'
});